@import "../../../index.scss";

.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + span {
			background-color: $app-secondary;
		}
		&:checked + span:before {
			transform: translateX(19px);
		}
	}
	span {
		position: absolute;
		cursor: pointer;
		border-radius: 34px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		transition: 0.3s;
		&:before {
			content: "";
			position: absolute;
			left: 4px;
			bottom: 4px;
			height: 12px;
			width: 12px;
			border-radius: 50%;
			background-color: white;
			transition: 0.4s;
		}
	}
}
