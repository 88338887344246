.wave-container {
	z-index: -1;
	position: absolute;
	bottom: 25vh;
	display: flex;
	width: 100%;
	.waves {
		width: 100%;
		height: 6vh;
		min-height: 250px;
		max-height: 180px;
	}
	.parallax {
		animation: moveForever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
	}
	.p1 {
		animation-delay: -2s;
		animation-duration: 25s;
	}
	.p2 {
		animation-delay: -3s;
		animation-duration: 10s;
	}
	.p3 {
		animation-delay: -4s;
		animation-duration: 13s;
	}
	.p4 {
		animation-delay: -5s;
		animation-duration: 20s;
	}
	@keyframes moveForever {
		0% {
			transform: translate3d(-90px, 0, 0);
		}
		100% {
			transform: translate3d(85px, 0, 0);
		}
	}
}
.wavebody {
	z-index: -10;
	position: absolute;
	bottom: 0;
	width: 100vw;
	height: 25vh;
	background-color: rgba(0, 25, 47, 0.9);
}
