@import "../../../index.scss";

.li {
	position: relative;
	span {
		cursor: pointer;
		width: 40px;
		height: 20px;
		border-radius: 20px;
		border: 1px solid slategray;
	}
}

.picker {
	z-index: 100;
	position: absolute;
	right: -45px;
}
