@import "../../index.scss";

.prepare {
	@include flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 5px;
	box-shadow: $soft-shadow;
	background-color: $app-primary;
	.workout_title {
		width: 100%;
		text-align: center;
		padding-bottom: 20px;
		margin: 0px;
		border-bottom: 2px solid $app-secondary;
	}
	.entities {
		width: 100%;
		.entities_title {
			padding: 20px;
			margin: 20px -20px;
			border: $light-border;
			background-color: $app-primary;
			filter: brightness(93%);
		}
		.dragging {
			background-color: lightgrey;
		}
		.ex_entity {
			user-select: none;
			@include flex;
			justify-content: space-between;
			padding: 10px 20px;
			border-radius: 5px;
			border-bottom: 2px solid lightgrey;
			.entity_main {
				flex: 1;
				p {
					font-size: 1.1rem;
					margin: 0;
				}
				ul {
					margin: 5px 0 5px 10px;
					padding: 0 0 0 10px;
					li {
						cursor: default;
						span {
							color: $app-secondary;
							font-size: 0.9rem;
							margin-left: 2px;
						}
					}
				}
			}
			.details {
				margin-right: 40px;
				text-align: right;
				@include media("<=phone") {
					margin-right: 4px;
				}
			}
		}
		.ci_entity {
			user-select: none;
			@include flex;
			justify-content: space-between;
			padding: 0px 20px;
			.entity_main {
				flex: 1;
				p {
					@include flex;
					justify-content: space-between;
					font-size: 1.1rem;
					margin: 0;
					margin-bottom: 10px;
				}
				ul {
					margin: 5px 0;
					padding: 0;
					li {
						cursor: default;
						@include flex;
						border-radius: 5px;
						justify-content: space-between;
						border-bottom: 2px solid lightgrey;
						p {
							margin: 0;
							margin-left: 20px;
							span {
								color: $app-secondary;
								font-size: 0.9rem;
								margin-left: 2px;
							}
						}
					}
				}
			}
			.details {
				margin-right: 40px;
				text-align: right;
			}
		}
		.handle {
			padding: 20px 20px;
			cursor: pointer;
			font-size: 1.2rem;
			border-radius: 5px;
			&:active {
				cursor: grabbing;
			}
			@include media("<=phone") {
				display: none;
			}
		}
	}
	@include media("<=phone") {
		.entities {
			.ex_entity,
			.ci_entity {
				padding: 10px 0;
			}
			.ci_entity {
				.entity_main {
					ul {
						li {
							padding: 10px 0;
						}
					}
				}
			}
		}
	}
}
