@import "../../index.scss";

.entities {
	flex: 2;
	@include flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 10px 0;
	.entity_cont {
		@include flex;
		flex: 1;
		min-width: 50%;
		max-width: 50%;
		.entity {
			position: relative;
			cursor: pointer;
			user-select: none;
			@include flex;
			width: 100%;
			height: 40px;
			padding: 5px;
			margin-right: 10px;
			margin-bottom: 10px;
			border-radius: 5px;
			box-shadow: $soft-shadow;
			background-color: $app-primary;
			transition: 0.2s;
			div {
				@include flex;
				width: 100%;
				text-align: center;
				p {
					margin: 0;
					height: 100%;
					width: 95%;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
			img {
				position: absolute;
				top: 50%;
				left: 5px;
				transform: translate(0, -50%);
				width: 15px;
			}
			&:hover {
				transition: 0.2s;
				color: white;
				background-color: $app-secondary;
			}
		}
		.active_entity {
			background-color: $app-secondary;
			color: white;
			transition: 0.2s;
		}
	}
	@include media("<=desktop") {
		width: 100%;
		.entity_cont {
			flex: 1;
			width: 100%;
			min-width: 100%;
			max-width: 100%;
		}
	}
	@include media("<=tablet") {
		width: 100%;
		order: 1;
		.entity_cont {
			.entity {
				margin-right: 0;
			}
		}
	}
	@include media("<=phone") {
		width: 100%;
		align-: stretch;
		order: 1;
		.entity_cont {
			padding: 0 5px;
			.entity {
				margin-right: 0;
				div {
					p {
						font-size: 1rem;
						width: 80%;
					}
				}
			}
		}
	}
}
