@import "../../../index.scss";

.circuit_detailer {
	flex: 2;
	margin-top: 10px;
	.detailer_cont {
		padding: 20px 10px;
		margin-right: 10px;
		border-radius: 5px;
		border: $light-border;
		box-shadow: $soft-shadow;
		background-color: $app-primary;
		h1 {
			user-select: none;
			text-align: center;
		}
		h2 {
			user-select: none;
		}
		.details {
			.detail {
				@include flex;
				padding-bottom: 10px;
				margin-bottom: 10px;
				justify-content: space-between;
				border-bottom: 2px solid lightgrey;
				p {
					margin: 0;
					margin-left: 10px;
				}
				input {
					font-size: 14px;
					width: 75px;
					padding: 2.5px;
					margin-right: 5px;
					border: $light-border;
				}
				select {
					font-size: 14px;
					outline: none;
					padding: 5px;
					padding-right: 10px;
					border-radius: 5px;
					border: $light-border;
				}
			}
		}
	}
	@include media("<=phone") {
		width: 100vw;
		order: 1;
		.detailer_cont {
			margin-right: 0;
			.details {
				.detail {
					width: 100%;
					p {
						padding-bottom: 10px;
						margin-left: 0;
					}
					div {
						input {
							flex: 1;
							max-width: 100px;
						}
					}
				}
			}
		}
	}
}

// DONT FORGET TO MAK3E INPUT AND SELECT FONT SIZE 16PX ON MOBILE
