@import "../index.scss";

.main {
	width: 100vw;
	@include flex;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	padding: 0 10px;
	padding-top: 60px;
	margin: 0 auto;
	max-width: $max-width;
	@include media("<=phone") {
		padding: 0;
		padding-top: 0px;
	}
}
