@import "../../index.scss";

.compose {
	form {
		@include flex;
		input {
			flex: 1;
			margin-right: 10px;
			padding: 5px;
			border: $light-border;
			// background-color: lighten($color: $body, $amount: 5%);
		}
		justify-content: space-between;
	}
	.submit {
		background-color: $app-secondary;
	}
	.tip {
		width: 100%;
		text-align: center;
		margin-bottom: 0;
	}
	@include media("<=desktop") {
		form {
			flex-direction: column;
			button {
				width: 100%;
			}
			input {
				width: 100%;
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
	@include media("<=phone") {
		width: 100%;
		margin: 0;
		padding: 0;
		form {
			margin-top: 10px;
		}
	}
}
