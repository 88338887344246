@import "../../index.scss";

.keystone {
	flex: 2;
	margin-left: 10px;
	@include media("<=tablet") {
		flex: 3;
	}
	@include media("<=phone") {
		flex: 1;
		width: 90%;
		margin-left: 0;
	}
}
