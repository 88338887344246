@import "../../index.scss";

.timer {
	width: 100%;
	text-align: center;
	font-size: 3.5rem;
	padding-bottom: 30px;
	margin: 40px 0;
	margin-bottom: 20px;
	border-bottom: 2px solid $app-secondary;
	font-weight: 400;
	@include media("<=phone") {
		font-size: 3rem;
		margin: 20px 0;
	}
}
