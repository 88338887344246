@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:300,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: darken(white, 40%);
}
::-webkit-scrollbar-thumb {
  background-color: darken(white, 70%);
  border-radius: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 7px;
}
::-webkit-scrollbar-track:horizontal {
  background-color: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: darken(white, 70%);
  border-radius: 5px;
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "./styles/common/breakpoints.scss";

$breakpoints: (
  "phone": 420px,
  "tablet": 900px,
  "desktop": 1200px,
) !default;

$max-width: 1500px;
$top: 70px;
$light-border: 1px solid rgba(185, 185, 185, 0.6);
$soft-shadow: -3px 3px 5px -5px black;
$warn: rgb(190, 9, 45);
:root {
  --app-background: #c7c7c7;
  --app-primary: #f0f0f0;
  --app-secondary: #206fa3;
}
$app-background: var(--app-background);
$app-primary: var(--app-primary);
$app-secondary: var(--app-secondary);

body {
  background-color: $app-background;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  line-height: 1.5rem;
  font-family: roboto;
  text-decoration: none;
  list-style: none;
}
h1,
h2,
h3,
h4 {
  font-family: Alegreya Sans SC;
  font-weight: 500;
  margin-top: 20px;
}
h1 {
  font-size: 1.8rem;
  letter-spacing: 1px;
}
.warn {
  background-color: $warn;
}
