@import "../../index.scss";

.actionbar_wrapper {
	user-select: none;
	z-index: 100;
	position: sticky;
	height: 70px;
	top: 60px;
	padding-top: 10px;
	background-color: $app-background;
	.actionbar {
		@include flex;
		justify-content: space-between;
		width: 100%;
		height: 60px;
		max-height: 60px;
		padding: 0 20px;
		border-radius: 5px;
		border: $light-border;
		box-shadow: $soft-shadow;
		background-color: $app-primary;
		h1 {
			margin-top: 20px;
			text-transform: capitalize;
		}
		@include media("<=tablet") {
			h1 {
				display: none;
			}
		}
		@include media("<=phone") {
			border-radius: 0;
			box-shadow: none;
			border-bottom: none;
		}
	}
	@include media("<=phone") {
		position: stick;
		top: 40px;
		max-height: 50px;
		width: 100vw;
		padding: 0;
		margin-bottom: 10px;
		h1 {
			display: none;
		}
	}
}
