@import "../index.scss";

.landing {
	@include flex;
	height: 100vh;
	main {
		@include flex;
		width: 100vw;
		height: 100vh;
		.float {
			user-select: none;
			position: relative;
			overflow: hidden;
			@include flex;
			justify-content: flex-start;
			flex-direction: column;
			min-height: 35vh;
			border-radius: 20px;
			box-shadow: 3px 3px 40px -20px black;
			background-color: #044f72;
			color: white;
			letter-spacing: 0.3px;
			.head {
				text-align: center;
				width: 100%;
				padding: 50px;
				box-shadow: 3px 3px 20px -10px black;
				background-color: #004464;
				.app_name {
					font-size: 2.5rem;
					margin: 0;
				}
			}
			.content {
				z-index: 50;
				@include flex;
				flex-direction: column;
				padding: 0 40px;
				.slogan {
					font-size: 1.4rem;
					margin: 0;
					margin: 50px;
				}
				.description {
					font-size: 1rem;
					margin: 0;
					margin-bottom: 5px;
				}
				button {
					cursor: pointer;
					padding: 10px 25px;
					margin-top: 50px;
					border-radius: 50px;
				}
			}
			.ls1,
			.ls2 {
				position: absolute;
				bottom: 0;
			}
			.ls1 {
				opacity: 086;
			}
			.ls2 {
				bottom: -10px;
				opacity: 1;
			}
		}
	}
}
