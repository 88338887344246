@import "../../index.scss";

.selector {
	.disclaimer {
		text-align: center;
		margin-bottom: 0;
	}
	ul {
		padding: 3px 0 3px 20px;
		padding-right: 10px;
		margin-bottom: 0;
		li {
			user-select: none;
			@include flex;
			justify-content: space-between;
			img {
				cursor: pointer;
				margin-left: auto;
				width: 15px;
			}
			span {
				color: $app-secondary;
				font-size: 0.9rem;
				margin-left: 2px;
			}
		}
	}
}
