@import "../../index.scss";

.manage {
	@include flex;
	align-items: flex-start;
	width: 100%;
	.actionbar_gap {
		// display: none;
		background-color: red;
	}
	.extension {
		z-index: 10;
		flex: 1;
		position: sticky;
		top: 140px;
		width: 100%;
		min-width: 275px;
		padding: 10px;
		padding-bottom: 20px;
		margin-top: 10px;
		border-radius: 5px;
		border: $light-border;
		box-shadow: $soft-shadow;
		background-color: $app-primary;
		.title {
			text-align: center;
			padding-bottom: 10px;
			border-bottom: 2px solid $app-secondary;
			font-weight: 400;
			font-size: 1.2rem;
		}
	}
	@include media("<=desktop") {
		.entities {
			width: 100%;
			.entity_cont {
				min-width: 100%;
				max-width: 100%;
			}
		}
	}
	@include media("<=tablet") {
		flex-direction: column;
		.actionbar_gap {
			display: block;
			z-index: 2;
			position: sticky;
			top: 130px;
			left: 0px;
			width: 100%;
			height: 10px;
			background-color: $app-background;
		}
		.extension {
			.title {
				margin-top: 0;
			}
		}
	}
	@include media("<=phone") {
		.extension {
			position: sticky;
			top: 100px;
			width: 100vw;
			padding-top: 0;
			margin-top: 0;
			max-height: 300px;
			overflow-y: scroll;
			border-radius: 0;
			border-top: none;
			.title {
				position: sticky;
				margin: 0;
				margin-bottom: 5px;
				background-color: $app-primary;
			}
			&::-webkit-scrollbar {
				width: 0;
			}
		}
	}
}
