@import "../../index.scss";

.navbar {
	z-index: unset;
	z-index: 110;
	user-select: none;
	@include flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px !important;
	padding: 0 25px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	box-shadow: $soft-shadow;
	background-color: $app-primary !important;
	* {
		color: black !important;
	}
	a {
		font-size: 2.1rem;
		letter-spacing: 0.5px;
		margin: 25px 0;
	}
	.desktop_logo {
		height: 40px;
	}
	.mobile_button {
		display: none;
	}
	@include media("<=phone") {
		z-index: 100;
		position: sticky;
		top: 0;
		height: 40px;
		box-shadow: none;
		width: 100vw;
		.mobile_button {
			@include flex;
		}
		.desktop_logo {
			display: none;
		}
		a {
			font-size: 1.6rem;
		}
	}
}
