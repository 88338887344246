@import "../../index.scss";

.active {
	@include flex;
	align-items: flex-start;
	width: 100%;
	margin-top: 10px;
	.workout {
		flex: 2;
		@include flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		margin: 0 10px 20px 0;
		padding: 20px;
		border-radius: 5px;
		box-shadow: $soft-shadow;
		background-color: $app-primary;
		.workout_title {
			width: 100%;
			text-align: center;
			padding-bottom: 20px;
			margin: 0px;
			border-bottom: 2px solid $app-secondary;
		}
		.entities {
			width: 100%;
			.entities_title {
				font-size: 1.8rem;
				padding: 20px;
				margin: 20px -20px;
				margin-top: 0;
				border: $light-border;
				background-color: $app-primary;
				filter: brightness(93%);
			}
			&:nth-child(2) .entities_title {
				margin-top: 20px;
			}
			.entity_main {
				flex: 1;
				p {
					font-size: 1.3rem;
					margin: 0;
					margin-bottom: 10px;
				}
				ul {
					margin: 5px 0;
					margin-bottom: 10px;
					padding: 0;
					padding-bottom: 10px;
					li {
						font-size: 1.1rem;
						cursor: default;
						margin-top: 5px;
						span {
							font-size: 1rem;
							color: $app-secondary;
						}
					}
				}
			}
			.details {
				margin-right: 20px;
				text-align: right;
				font-size: 1.1rem;
			}
			.ex_entity {
				@include flex;
				justify-content: space-between;
				padding: 15px 0px;
				border-bottom: 2px solid lightgrey;
				ul {
					margin: 5px 0 5px 10px;
					li {
						margin-top: 5px;
					}
				}
			}
			.ci_entity {
				@include flex;
				justify-content: space-between;
				p {
					@include flex;
					justify-content: space-between;
				}
				ul {
					li {
						text-align: center;
						padding: 10px;
						border-bottom: 2px solid lightgrey;
						span {
							place-items: 5px;
						}
					}
				}
			}
		}
	}
	@include media("<=tablet") {
		flex-direction: column;
		.workout {
			order: 1;
			margin-top: 10px;
		}
	}
	@include media("<=phone") {
		margin-top: 0;
		flex-direction: column;
		.workout {
			order: 2;
			.entities {
				.entities_title {
					font-size: 1.5;
				}
				.entity_main {
					p {
						font-size: 1, 1;
					}
					ul {
						li {
							font-size: 1;
							span {
								font-size: 0.9rem;
							}
						}
					}
					.details {
						margin-right: 10px;
					}
				}
			}
		}
	}
}
